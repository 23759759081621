import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "checkbox", "dropdown" ];

  connect() {
    if (this.checkboxTarget.checked) {
      this.dropdownTarget.style.display = "block";
    } else {
      this.dropdownTarget.style.display = "none";
    }
    
    this.checkboxTarget.addEventListener("change", (event) => {
      if (event.target.checked) {
        this.dropdownTarget.style.display = "block";
      } else {
        this.dropdownTarget.style.display = "none";
      }
    });
  }
}
