import { Controller } from "stimulus";
import $ from 'jquery';
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.css";

export default class extends Controller {
  static targets = [ "table" ]
  static values = { nosort: Boolean, sortlast: Boolean, dontsort: String, scoreenabled: Boolean }

  connect() {
    let options = {
      "pageLength": 50,
      responsive: true,
      columnDefs: [
        { orderable: false, targets: -1 },
      ],
      aaSorting: [],
      "autoWidth": false,
      scrollY: 750,
      scrollCollapse: true
    };

    if (this.sortlastValue === true) {
      options["columnDefs"] = []
    }

    if (this.nosortValue === true) {
      options["ordering"] = false;
    }

    if (this.dontsortValue) {
      this.dontsortValue.split(",").forEach(item => {
        let target = Number(item);
        if (!this.scoreenabledValue) {
          target--;
        }
        options["columnDefs"].push({orderable: false, targets: target})
      })
    }
    
    let table = $(`#${this.tableTarget.id}`).DataTable(options);

    document.addEventListener("turbolinks:before-cache", function() {
      table.destroy();
    });
  }

}