import Rails from '@rails/ujs';
import autoComplete from '@tarekraafat/autocomplete.js';
import "@tarekraafat/autocomplete.js/dist/css/autoComplete.02.css";

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "suggestionsContainer", "suggestionsCard" ]
  static values = { endpoint: String }

  connect() {
    console.log(this.endpointValue);
    this.autoCompleteJS = null;

    Rails.ajax({
      url: this.endpointValue,
      type: 'get',
      success: response => {
        this.autoCompleteJS = new autoComplete(
          {
            data: {
              src: response
            },
            resultItem: {
              highlight: {
                render: true
              }
            },
            resultList: {
              tabSelect: true
            },
            events: {
              input: {
                selection: (event) => {
                  const selection = event.detail.selection.value;
                  this.autoCompleteJS.input.value = selection;
                }
              }
            }
          }
        );

      }
    })

  }
}
